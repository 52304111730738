
import { defineComponent, ref, watchEffect, watch, reactive } from 'vue';
import { useApiStore } from '@/store/store-index';
import formValidation from '@/compositions/formValidation';
import { useI18n } from 'vue-i18n'
import ErrorToast from '@/components/ErrorToast.vue';
import CustomToast from '@/components/CustomToast.vue';

export default defineComponent({
  components: { CustomToast, ErrorToast },
  setup() {
    const apiStore = useApiStore();
    const { t } = useI18n()
    const toastType = ref('');
    const isToastVisible = ref(false);
    const toastMessage = ref('');

    let form = reactive({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
    });


    const resetForm = () => {
      apiStore.resetUser()
      showResetButton.value = false
      formChanged.value = false
    };

    watchEffect(() => {
      form.firstname = apiStore.users.firstname;
      form.lastname = apiStore.users.lastname;
      form.email = apiStore.users.email;
      form.phone = apiStore.users.phone;
    });

    let validation = reactive({
      email: {
        state: null as boolean | null,
        message: '',
      }
    });

    const formChanged = ref(false);
    const showResetButton = ref(false);
    let loaded = ref(false);
    const onTypingPhone = (phone: any) => {
      const formattedPhone = phone.replace(/[^0-9+]/g, '').slice(0, 12);
      form.phone = formattedPhone;
    };    
    let rules = {
            email: {
                rule: 'required=true|type=email' as string,
                message: 'Une adresse e-mail valide est requise'  as string,
            }
        };
    
        watch(
            form,
            () => {       
                validation = Object.assign(validation, formValidation(rules, form, t));
                if (form.firstname != apiStore.users.firstname ||  form.lastname != apiStore.users.lastname  ||  form.email != apiStore.users.email || form.phone != apiStore.users.phone ) {
                  showResetButton.value = true
                  validation.email.state === true ? (formChanged.value = true) : (formChanged.value = false);
               }
                else if (form.firstname == apiStore.users.firstname &&  form.lastname ==  apiStore.users.lastname &&  form.email == apiStore.users.email && form.phone == apiStore.users.phone ) {
                  formChanged.value = false
                  showResetButton.value = false
                }
            },
            { immediate: true }
        );

    const updateProfile = (user: any) => {
      type Payload = {
        email: any;
        firstname: any;
        lastname: any;
        phone: any;
      };
      let payload: Payload = {
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone,
      };
      apiStore
        .patch('/update-info', payload)
        .then((res:any) => {
          toastType.value = 'CustomToast';
          toastMessage.value = 'Profil modifié avec succès';
          isToastVisible.value = true;
          setTimeout(() => {
            isToastVisible.value = false;
          }, 2000);
            resetForm()
        })
        .catch(() => {
          toastType.value = 'ErrorToast';
          toastMessage.value = 'Erreur lors de la mise à jour du profil.';
          isToastVisible.value = true;
          setTimeout(() => {
            isToastVisible.value = false;
          }, 2000);
        });
    };
    return {
      form,
      validation,
      formChanged,
      showResetButton,
      isToastVisible,
      toastMessage,
      toastType,
      loaded,
      t,
      updateProfile,
      resetForm,
      onTypingPhone
    };
  },
});
