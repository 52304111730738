
import { defineComponent, computed } from 'vue';
import { useApiStore, useHomeStore } from '@/store/store-index';
import { storeToRefs } from "pinia";
import moment from 'moment';
import PeriodPicker from './periodPicker.vue';
import { useFilterStore } from '@/store/store-index';
import { useI18n } from 'vue-i18n'

export default defineComponent({
    components: { PeriodPicker },
      setup () {
          const filterStore = useFilterStore();
          const apiStore = useApiStore();
          const homeStore = useHomeStore();
          const { invoicesFilter } = storeToRefs(homeStore);
          const checkPos = computed(() => apiStore.posBrand);
          const { t } = useI18n()

          return {
              filterStore,
              apiStore,
              invoicesFilter,
              checkPos,
              t
          };
      },
      data() {
          return {

              rangePeriod: [] as any,
              selectedDate: null,
              showCustomDate: false,
              showPeriodPicker: false,
              hidePeriodPicker: true,
              filteredInvoices: [] as any,
              selectedInvoices: [],
              periodSelected: "",
              period_year: '',
              checked_pos: [] as string[],
              pointCode: "" as string,
              selected: [],
              filter_periods: [
                  {text: 'Ce mois-ci', value: 'thisMonth'},
                  {text: '3 Derniers mois', value: 'lastTrimester'},
                  {text: '6 Derniers mois', value: 'lastSemester'},
                  {text: 'Personnalisé', value: 'customPeriodHub'}
              ]
          }
       },
      watch: {
      'apiStore.invoices': {
        immediate: true,
        handler() {
          this.invoicesFilter = this.filterInvoices();
          this.showCustomDate = false
        }
      },
        checked_pos() {
            this.invoicesFilter = this.filterInvoices();
        },
        },
    methods: {
          setDate(period: any){
            this.rangePeriod = period
            this.rangePeriod[0] = moment(this.rangePeriod[0]).format("DD/MM/YYYY");
            this.rangePeriod[1] = moment(this.rangePeriod[1]).format("DD/MM/YYYY")
          },
          onDatePicker(period: string) {
            if (period === 'customPeriodHub') {
                    this.showPeriodPicker = true;
                } else {
                    this.showPeriodPicker = false;
                }
          },
          onPeriodSelect(period: string) {
            if (period === 'customPeriodHub') {
                    this.showCustomDate = true;
                } else {
                    this.showCustomDate = false;
                }
                this.periodSelected = period
                this.invoicesFilter = this.filterInvoices();
                (this.$refs.drowpdown_periods as any).hide();
          },
          onCustomPeriodSelect(period: string) {
                period = "customPeriodHub"
            this.onPeriodSelect(period)
          },
          filterInvoices() {
            let filteredInvoices = this.apiStore.invoices;
            if (this.checked_pos.length > 0) { 
                filteredInvoices = filteredInvoices.filter(invoice => this.checked_pos.includes(invoice.point_code));
            }
           if (this.periodSelected === 'thisMonth') {
            const currentMonth = moment().month();
            const currentYear = moment().year();
            filteredInvoices = filteredInvoices.filter((invoice: any) => {
                const invoiceMonth = moment(invoice.date, 'DD/MM/YYYY').month();
                const invoiceYear = moment(invoice.date, 'DD/MM/YYYY').year();
                return invoiceMonth === currentMonth && invoiceYear === currentYear;
            });
          }
          else if  (this.periodSelected === 'lastTrimester') {
              const currentMonth = moment();
              const threeMonthsAgo = moment().subtract(3, 'months');
              filteredInvoices = filteredInvoices.filter((invoice: any) => {
                  const invoiceDate = moment(invoice.date, 'DD/MM/YYYY');   
                  return invoiceDate.isBetween(threeMonthsAgo, currentMonth, null, '[]');
              });
          }
          else if (this.periodSelected === 'lastSemester') {
              const currentMonth = moment();
              const sixMonthsAgo = moment().subtract(6, 'months');
              filteredInvoices = filteredInvoices.filter((invoice: any) => {
                  const invoiceDate = moment(invoice.date, 'DD/MM/YYYY');
                  return invoiceDate.isBetween(sixMonthsAgo, currentMonth, null, '[]');
              });

          } 
          else if (this.periodSelected === 'customPeriodHub') {
              const startDate = moment(this.rangePeriod[0], 'DD/MM/YYYY');
              const endDate = moment(this.rangePeriod[1], 'DD/MM/YYYY');
              filteredInvoices = filteredInvoices.filter((invoice: any) => {
                  const invoiceDate = moment(invoice.date, 'DD/MM/YYYY');
                  return invoiceDate.isBetween(startDate, endDate, null, '[]');
              });
          } 
          return filteredInvoices;
          },
          onSelectAll() {
            this.filterStore.selectFilterItem('all')
            let filtered = this.filterInvoices();
            this.invoicesFilter = filtered
            },
          onSelectSubscription () {
            this.filterStore.selectFilterItem('sub')
            let filtered = this.filterInvoices();
            return this.invoicesFilter = filtered.filter((invoice: any) => invoice.status == 2 ||  invoice.status == 1);
            },
          onSelectCampaign () {
            this.filterStore.selectFilterItem('camp')
            let filtered = this.filterInvoices();
            this.invoicesFilter = filtered.filter((invoice: { status: number; }) => invoice.status === 3 || invoice.status === 4);
          },
          clearPosSelection() { 
            this.invoicesFilter = this.apiStore.invoices;
            this.checked_pos = [] 
          },
          clearPeriodSelection() {
            this.periodSelected = "";
            this.selectedDate = null
            this.showCustomDate = false
            this.invoicesFilter = this.filterInvoices();
        },
  }
  });
  