import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_nav_item = _resolveComponent("b-nav-item")!
  const _component_b_nav = _resolveComponent("b-nav")!
  const _component_b_navbar = _resolveComponent("b-navbar")!

  return (_openBlock(), _createBlock(_component_b_navbar, {
    class: "side-bar",
    sticky: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_nav, { vertical: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_nav_item, {
            to: "/profile",
            class: _normalizeClass({'selected': _ctx.store.selectedNavItem === 'profile'}),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.store.selectNavItem('profile')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['far', 'user'],
                class: "nav-icon"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('profile')), 1)
            ], undefined, true),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_b_nav_item, {
            to: "/password",
            class: _normalizeClass({'selected': _ctx.store.selectedNavItem === 'password'}),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.store.selectNavItem('password')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fasl', 'lock'],
                class: "nav-icon"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('safety')), 1)
            ], undefined, true),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_b_nav_item, {
            to: "/invoices",
            class: _normalizeClass({'selected': _ctx.store.selectedNavItem === 'invoices'}),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.store.selectNavItem('invoices')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fasl', 'file-invoice'],
                class: "nav-icon"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('invoices')), 1)
            ], undefined, true),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_b_nav_item, {
            to: "/pos",
            class: _normalizeClass({'selected': _ctx.store.selectedNavItem === 'pos'}),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.store.selectNavItem('pos')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fasl', 'location-dot'],
                class: "nav-icon"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('pos')), 1)
            ], undefined, true),
            _: 1
          }, 8, ["class"])
        ], undefined, true),
        _: 1
      })
    ], undefined, true),
    _: 1
  }))
}