
import { defineComponent, ref } from 'vue';
import { BNav, BNavItem, BNavbar } from 'bootstrap-vue';
import { useNavStore } from '@/store/store-index';
import { useI18n } from 'vue-i18n'


export default defineComponent({
  components: {
    BNavbar,
    BNav,
    BNavItem,
  },
  setup() {
    const store = useNavStore();
    const selectedItem = ref('');
    const { t } = useI18n()
    return { 
      selectedItem,
      store,
      t
    };
  },
  created () {    
    this.store.selectedNavItem = 'profile'
  }
});
