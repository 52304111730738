
import { defineComponent, reactive, watch, ref, computed } from 'vue';
import { useApiStore } from '@/store/store-index';
import { useToast } from "vue-toastification";
import { useI18n } from 'vue-i18n'
import formValidation from '@/compositions/formValidation';
import ErrorToast from '@/components/ErrorToast.vue';
import CustomToast from '@/components/CustomToast.vue';

export default defineComponent({
    components: { CustomToast, ErrorToast },
    setup () {
        const apiStore = useApiStore();
        const { t } = useI18n()
        const toast = useToast();
        let formChanged = ref(false)        
        const user = computed(() => apiStore.users);
        const toastType = ref('');
        const isToastVisible = ref(false);
        const toastMessage = ref('');
        const validateMessage = ['1 Majuscule', '12 Caractères', '1 Chiffre', '1 Caractère spécial']
        let messageColors = ['grey', 'grey', 'grey', 'grey']
        const showResetButton = ref(false);

        let form = reactive({
            password: '' as string,
            confirmPassword: '' as string,
            oldPassword: '' as string,
        });

        let rules = {
            password: {
                rule: 'required=true|type=password' as string,
                message: [] as any,
            },
            confirmPassword: {
                rule: 'required=true' as string,
                message: '' as any,
            },
            oldPassword: {
                rule: 'required=true' as string,
                message: '' as any,
            }
        };
            let validation = reactive({
                password: { state: null as boolean | null, message: [] as any },
                confirmPassword: { state:  null as boolean | null, message: "" as any },
                oldPassword: { state:  null as boolean | null, message: "" as any }
            });
            
            let passwordShowState = reactive([false, false, false]);
            const showPassword = (index: number) => { 
            passwordShowState[index] = !passwordShowState[index];
            };

            const resetForm = () => {
            form.password = '';
            form.confirmPassword = '';
            form.oldPassword = '';
            validation.password.state = true;
            validation.password.message = [];
            validation.confirmPassword.state = true;
            validation.confirmPassword.message = '';
            validation.oldPassword.state = true;
            validation.oldPassword.message = '';
            formChanged.value = false
        };
            const updatePassword = (user: { email: any; }) => {
            type Payload = {
            email: any;
            password: any;
            confirmPassword: any;
            oldPassword: any;
            };

            let payload: Payload = {
            email: user.email,
            password: form.password,
            confirmPassword: form.confirmPassword,
            oldPassword: form.oldPassword
            };

            if (form.confirmPassword === form.password) {
            (apiStore.post('/update-password', payload, null) as Promise<Request>)
            .then(() => {
                    toastType.value = 'CustomToast';
                    toastMessage.value = "Le mot de passe a été mis à jour avec succès."
                    isToastVisible.value = true;
                    setTimeout(() => {
                        isToastVisible.value = false;
                    }, 2000);
                    resetForm()
            })
            .catch((error: any) => {
                if (error.response.status === 400) {
                    toastType.value = 'ErrorToast';
                    toastMessage.value = "L'ancien mot de passe ne correspond pas"
                    isToastVisible.value = true;
                    setTimeout(() => {
                        isToastVisible.value = false;
                    }, 2000);
                } else {
                    toastType.value = 'ErrorToast';
                    toastMessage.value = 'Erreur lors de la mise à jour du profil.';
                    isToastVisible.value = true;
                    setTimeout(() => {
                        isToastVisible.value = false;
                    }, 2000);
                    
                }
            });
            } else {
              toast.error("Les mots de passe ne correspondent pas.", { timeout: 2000 });
            }
        };
        
        watch(
            form,
            () => {       
                (form.password.length > 0  || form.confirmPassword.length > 0 ||  form.oldPassword.length > 0) ? showResetButton.value = true :  showResetButton.value = false
            },
            { immediate: true }
        ); 
        const validateSpecial = (password: any, messages: string[], messageColors: string[]) => {
            password.match(/\d/) ? messageColors[2] = '#02B15D' : messageColors[2] = 'grey';
            password.match(/[A-Z]/) ? messageColors[0] = '#02B15D' : messageColors[0] = 'grey';
            password.length >= 12 ? messageColors[1] = '#02B15D' : messageColors[1] = 'grey';
            password.match(/[^\w\s]/) ? messageColors[3] = '#02B15D' : messageColors[3] = 'grey';
            }

        const validateInput = (form: any) => {  
               if (form) {
                    validation = Object.assign(validation, formValidation(rules, form, t))
                if (form.password) {
                validateSpecial(form.password, validateMessage, messageColors)
                }
                if ((form.confirmPassword.length > 0 || form.password.length > 0)  && form.confirmPassword != form.password) {
                validation.confirmPassword.message = "Les mots de passe ne correspondent pas.";
                validation.confirmPassword.state = false;
                }
                if (validation.password.state === true && form.confirmPassword.length > 0 
                    && form.confirmPassword === form.password && form.oldPassword.length > 0) {
                    formChanged.value = true
                } else {
                    formChanged.value = false
                }
                     messageColors = messageColors
            }
            };
        return {
            apiStore,
            form,
            validation,
            formChanged,
            toast,
            passwordShowState,
            user,
            isToastVisible,
            toastMessage,
            toastType,
            updatePassword,
            showPassword,
            resetForm,
            validateInput,
            validateMessage,
            messageColors,
            showResetButton
        };
    },
});
