
  import { defineComponent, ref, computed, getCurrentInstance} from 'vue';
  import { useApiStore, useHomeStore } from '@/store/store-index';
  import { useToast } from "vue-toastification";
  import { storeToRefs } from "pinia";
  import * as FileSaver from 'file-saver';
  import Request from '@/interfaces/common-request';
  import "@vuepic/vue-datepicker/dist/main.css";
  import InvoicesFilterVue from "./invoicesFilter.vue";
  import { BButton,  BTable } from "bootstrap-vue";
  import ErrorToast from '@/components/ErrorToast.vue';
  import CustomToast from '@/components/CustomToast.vue';

  export default defineComponent({
    components: { InvoicesFilterVue, BButton,  BTable, ErrorToast,CustomToast },
      setup () {
        const toastType = ref('');
        const isToastVisible = ref(false);
        const toastMessage = ref('');
        const componentInstance = getCurrentInstance();
        const modalId = ref('');
        const pdfsrc = ref('');
        const apiStore = useApiStore();
        const toast = useToast();
        const homeStore = useHomeStore();
        const { invoicesFilter } = storeToRefs(homeStore);
        const currentPage = ref(1);
        const perPage = ref(25);
        const count = ref(0);
        const sortBy = ref('date');
        const sortDesc = ref<Record<string, boolean>>({});
        const selectAllInvoices = ref(false);
        const filteredInvoices = ref([]);
        const selectedInvoices = ref([]);
        const pointCode = ref('');    
        const checkPos = computed(() => apiStore.posBrand);

        const close = () => {
            pdfsrc.value = '';
            return pdfsrc.value;
        };
            const getIcon = (fieldKey: string) => {
            return ['fas', sortDesc.value[fieldKey] ? 'chevron-up' : 'chevron-down'];
            };

            const sortIcon = (fieldKey: string) => {
            sortDesc.value[fieldKey] = !sortDesc.value[fieldKey];
            return sortDesc.value[fieldKey];
            };
        const selectAllInvoicesChanged = (invoicesFilter: any) => {
            selectedInvoices.value = selectAllInvoices.value ? invoicesFilter : [];
            return selectedInvoices
        };

        const formatCurrency = (value: any) => {
                let formattedValue = String(value);
                formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                return formattedValue;
            }

        const downloadInvoice = async (items: any): Promise<void> => {
            type Payload = {
                invoices: any;
            };
            let payload: Payload = {
                invoices: items
            };

            (apiStore.post('/download-invoice', payload, {responseType: 'blob'}) as Promise<Request>)
                .then((res :Request) => {
                    let blob: any
                    if (res.data instanceof Blob) {
                        if (res.data.type === "application/pdf") {
                         blob = new Blob([res.data as unknown as Blob], {type: 'application/pdf'});
                            FileSaver.saveAs(blob,'test'.concat('.pdf'), {autoBom: false});
                            toastType.value = 'CustomToast';
                            toastMessage.value = 'Factures téléchargés avec succès';
                            isToastVisible.value = true;
                            setTimeout(() => {
                                isToastVisible.value = false;
                            }, 2000);
                        }
                         else {
                            blob = new Blob([res.data as unknown as Blob], {type: 'application/zip'});
                            FileSaver.saveAs(blob, `factures`.concat("_", '.zip'), {autoBom: false} );
                            toastType.value = 'CustomToast';
                            toastMessage.value = 'Factures téléchargés avec succès';
                            isToastVisible.value = true;
                            setTimeout(() => {
                                isToastVisible.value = false;
                            }, 2000);
                        }
                    }
                })
                .catch((err :Error) => {
                    console.error(err);  
                    toastType.value = 'ErrorToast';
                    toastMessage.value = "Probleme lors de telechargement de facture";
                    isToastVisible.value = true;
                    setTimeout(() => {
                    isToastVisible.value = false;
                    }, 2000);
                });

        }

        const openInvoice = async (item: any): Promise<void> => {
            try {
                if (!item.details.invoiceName) {
                throw new Error('Invoice not found');
                }
                modalId.value = `modal-pdf-${item.id}`;
                const pdfModal = componentInstance?.refs?.pdf_modal as { show: () => void; hide: () => void; } | undefined;
                if (!pdfModal) {
                throw new Error('PDF modal not found');
                }
                pdfModal.show();
                type Payload = {
                        invoices: any;
                    };
                    let payload: Payload = {
                        invoices: item
                    };
                const res : any = await apiStore.post('/download-invoice', payload, { responseType: 'blob' });
                const blob = new Blob([res.data as unknown as Blob], {
                type: 'application/pdf',
                });
                const objectUrl = URL.createObjectURL(blob);
                pdfsrc.value = objectUrl;
            } catch (err : any) {
                console.error(err);
                const errorMessage = err.message || 'An error occurred';
                const pdfModal = componentInstance?.refs?.pdf_modal as { show: () => void; hide: () => void; } | undefined;
                if (pdfModal) {
                    pdfModal.hide();
                    }
                toastType.value = 'ErrorToast';
                toastMessage.value = errorMessage;
                isToastVisible.value = true;
                setTimeout(() => {
                isToastVisible.value = false;
                }, 2000);
            }
            };
          return {
              apiStore,
              toast,
              isToastVisible,
              toastMessage,
              toastType,
              invoicesFilter,
              currentPage,
              perPage,
              count,
              sortBy,
              sortDesc,
              modalId,
              filteredInvoices,
              selectedInvoices,
              pdfsrc,
              pointCode,
              checkPos,
              selectAllInvoices,
              close,
              openInvoice,
              downloadInvoice,
              selectAllInvoicesChanged,
              formatCurrency,
              sortIcon,
              getIcon 

          };
      },
      data() {
          return {
              fields: [
              {
              key: 'selected',
              label: '',
              sortable: false,
              thStyle: { width: "16px"},
              },
              {
              key: 'name',
              label: 'Facture',
              sortable: true,
              thStyle: { width: "fit-content" }
              },
              {
              key: 'point_code',
              label: 'Code',
              sortable: true,
              thStyle: { width: "100px" },
              },
              {
              key: 'point_name',
              label: 'Point de vente',
              sortable: true,
              thStyle: { width: "224px  " },
              },
              {
              key: 'type',
              label: 'Type',
              sortable: true,
              thStyle: { width: "fit-content" }
              },
              {
              key: 'date',
              label: 'Date',
              sortable: true,
              thStyle: { width: "100px" },
              },
              { 
              key: 'value',
              label: 'Montant',
              sortable: true,
              thStyle: { display: "flex", justifyContent: "flex-end", width: "auto"}
              },
              { 
              key: 'view',
              label: 'Actions' ,
              sortable: false,
              thStyle: { width: "96px" }
              }
        ],
          }
       },
  });
  