
import { defineComponent } from 'vue';
import { useApiStore } from '@/store/store-index';
import * as FileSaver from 'file-saver';
import Request from '@/interfaces/common-request';

export default defineComponent({
    setup () {
        const apiStore = useApiStore();
        return {
            apiStore,
        };
    },
    data() {
        return {
            selectedPos: [],
            alias: {},
            password: null,
            pdfsrc: ""
        }
     },
    computed: {
       associe() {
             let alias: any = [];
             this.apiStore.associes.forEach((element: any) => {
                alias.push(element)
             });
            return alias
    },
      users() : object {
            let users: any = this.apiStore.users 
            let marques: any = [];
            let pos: any = [];
            for (let item of this.apiStore.posBrand) {
                marques.push(...Object.keys(item))
                pos.push(...Object.values(item))
            }
            users["brands"] = marques
            users["point_code"] = pos
            return users;
        },
        invoices() : object {
            let invoices: any = {};
            invoices = this.apiStore.invoices
            return invoices;
        },
    },
    methods: {
        deleteAlias(user: { firstname: any; lastname: any; email: any; }) {

            console.log(user)
            type Alias = {
            firstname: any;
            lastname: any;
            email: any;
            point_code: any
            };

            let alias: Alias = {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            point_code: this.selectedPos
            };

        // (this.apiStore.post('/deleteAlias', alias, null) as Promise<Request>).then((res :Request) => {
        //     console.log(res.data)
        //     }).catch((err :Error) => {
        //         console.error(err);  
        //     }).finally(() => {
        //         console.log("done")
        //     })

            return user
            
        },
        addAliase (user: any): void { 
            type Alias = {
            firstname: any;
            lastname: any;
            email: any;
            point_code: any
            };

            let alias: Alias = {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            point_code: this.selectedPos
            };

        (this.apiStore.post('/alias', alias, null) as Promise<Request>).then((res :Request) => {
            console.log(res.data)
            }).catch((err :Error) => {
                console.error(err);  
            }).finally(() => {
                console.log("done")
            })

            return user
        },
        close () {
        this.pdfsrc = ""
        return this.pdfsrc
        },
        updatePassword(user: any): void {  
            type Payload = {
            email: any;
            password: any

            };
            let payload: Payload = {
                email: user.email,
                password: this.password,

            };

            (this.apiStore.post('/update-password', payload, null) as Promise<Request>).then((res :Request) => { 
            console.log(res.data)
            })      
            this.password = null  
        },
        updateProfile (user: any): void { 
            type Payload = {
            email: any;
            firstname: any
            lastname: any
            address: any
            };
            let payload: Payload = {
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                address: user.address
            };

            (this.apiStore.patch('/update-info', payload) as Promise<Request>).then((res :Request) => { 
                console.log(res.data)
            })      
        },
        openInvoice(item: any): void { 
            // option to just view
            type Payload = {
            invoice_name: any;
            brand: any;
            };
            let payload: Payload = {
                invoice_name: item.details.invoiceName,
                brand: item.brand
            };

            (this.apiStore.post('/downloadInvoice', payload, {responseType: 'blob'}) as Promise<Request>).then((res :Request) => {
            let blob = new Blob([res.data as unknown as Blob], {type: 'application/pdf'});
            const objectUrl = URL.createObjectURL(blob);
            this.pdfsrc = objectUrl

            }).catch((err :Error) => {
                console.error(err);  
            }).finally(() => {
                console.log("done")
            })
        },
        downloadInvoice(item: any): void {
            type Payload = {
            invoice_name: any;
            brand: any;
            };
            let payload: Payload = {
                invoice_name: item.details.invoiceName,
                brand: item.brand
            };

        (this.apiStore.post('/downloadInvoice', payload, {responseType: 'blob'}) as Promise<Request>).then((res :Request) => {
        let blob = new Blob([res.data as unknown as Blob], {type: 'application/pdf'});
         FileSaver.saveAs(
            blob,
          `${item.details.invoiceName}`.concat('.pdf')
      );

  }).catch((err :Error) => {
      console.error(err);  
  }).finally(() => {
      console.log("done")
  })
}
}
});
