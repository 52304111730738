
import { defineComponent } from 'vue';
import { useApiStore } from '@/store/store-index';

export default defineComponent({
    setup() {
        const apiStore = useApiStore();
        return {
            apiStore,
        };
    },
    data() {
        return {
            selectedPos: [],
            accounter: {},
            searchFilter: ""
        }
    },
    computed: {
    pos(): Array<any> {
        if (this.searchFilter.length > 0) {
            return this.apiStore.payment.filter(
            (item) =>
            item.point_code.toLocaleLowerCase().includes(this.searchFilter.toLocaleLowerCase()) ||
            item.brand.toLocaleLowerCase().includes(this.searchFilter.toLocaleLowerCase()) ||
            item.point_name.toLocaleLowerCase().includes(this.searchFilter.toLocaleLowerCase())
      );
    }
    else {
        return this.apiStore.payment
    }},
    },
    methods: {
       
    }
});
