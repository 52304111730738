
import { defineComponent } from 'vue';
import { useAuthStore } from '@/store/store-index';
export default defineComponent({
  setup() {
    const authStore = useAuthStore();

    return {
      authStore
    };
  },

  data () {
    return {
      form: {
        login: '',
        password: '',
      }
    };
  },
  methods: {
    onSubmit () {
      this.authStore.login(this.form);
      this.$router.push('home') 
    },
  }
});
