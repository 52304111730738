<template>
    <div class="error-toast">
      <div class="toast-container">
        <div class="toast-message">{{ message }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ErrorToast',
    props: {
      message: String,
    },
  };
  </script>
  
  <style scoped>
  .error-toast {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .toast-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FBE8E8;
    color: #1F1F1F;
    padding: 10px;
    border-radius: 5px;
    margin: 10px auto;
  }
  
  .toast-message {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
  </style>
  