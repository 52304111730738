import { defineStore } from 'pinia';
import { axiosInstance } from '@/axiosInstance';

interface User {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    }

export const useApiStore = defineStore('useApiStore', {
    state: () => ({
        users: {} as User,
        invoices: [] as any[],
        payment: [] as any[],
        associes: [] as any[],
        posBrand: [] as any[]
    }),
    getters: {},
    actions: {
        resetUser() {
            return this.get('/user-info').then((res: any) => {
                this.users = res.data;
              }).catch((err) => {
                console.error(err);
              });
        },
        get(url: string): Promise<object> {
            return axiosInstance(url);
        },
        post(url :string, payload: any, headers : any) :Promise<object>{
            return axiosInstance.post(url,  payload, headers);
        },
        patch(url :string, payload: any) :Promise<object>{
            return axiosInstance.patch(url,  payload);
        },
        init(): Promise<any> {
            const promises = [
                this.get('/user-info'),
                this.get('/user-invoices'),
                this.get('/payment-info')
            ]
            return Promise.all(promises as Array<Promise<Request>>).then((res :any) => {
                this.users = res[0].data;
                this.invoices = res[1].data;
                this.payment = res[2].data;
                this.associes = res[0].data.associes;
                this.posBrand =  res[0].data.posBrand
            }).catch((err) => {
                console.error(err);
                
            })
            
        },
    },
});

