
import { defineComponent } from 'vue';
import { useApiStore } from '@/store/store-index';
import Request from '@/interfaces/common-request';

export default defineComponent({
    setup () {
        const apiStore = useApiStore();
        return {
            apiStore,
        };
    },
    data() {
        return {
            selectedPos: [],
            alias: {},
            fields: [
            {
            key: 'firstname',
            label: 'Nom',
            },
            {
            key: 'email',
            label: 'Email',
            },
            {
            key: 'status',
            label: 'Role',
            },
            { 
            key: 'point_code',
            label: 'Points de Vente' 
            },
            { 
            key: 'view',
            label: 'Actions' 
            }
      ],
        }
     },
    computed: {
        users() {
            let users: any = this.apiStore.users 
            let pos: any = [];
            console.log(this.apiStore.posBrand)
            for (let item of this.apiStore.posBrand) {
                pos.push(item)
            }
            
            users["point_code"] = pos
            return users;
        },
        associe() {
             let alias: any = [];
             this.apiStore.associes.forEach((element: any) => {
                alias.push(element.user)
             });
            return alias
    }
    },
    methods: {
        deleteAlias(user: { firstname: any; lastname: any; email: any; }) {
            type Alias = {
            email: any;
            point_code: any
            };

            let alias: Alias = {
            email: user.email,
            point_code: this.selectedPos
            };

            (this.apiStore.post('/delete-alias', alias, null) as Promise<Request>).then((res :Request) => {
                console.log(res.data)
                }).catch((err :Error) => {
                    console.error(err);  
                }).finally(() => {
                    console.log("done")
                })

            return user
            
        },
        editAlias (item: any): void { 
            type Alias = {
            firstname: any;
            lastname: any;
            email: any;
            point_code: any
            };

            let alias: Alias = {
            firstname: item.firstname,
            lastname: item.lastname,
            email: item.email,
            point_code: this.selectedPos
            };
           
            (this.apiStore.patch('/edit-alias', alias) as Promise<Request>).then((res :Request) => {
            console.log(res.data)
            }).catch((err :Error) => {
                console.error(err);  
            }).finally(() => {
                console.log("done")
            })

        },
        addAlias(user: any): void { 
            type Alias = {
            firstname: any;
            lastname: any;
            email: any;
            point_code: any
            };

            let alias: Alias = {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            point_code: this.selectedPos
            };

        (this.apiStore.post('/alias', alias, null) as Promise<Request>).then((res :Request) => {
            console.log(res.data)
            }).catch((err :Error) => {
                console.error(err);  
            }).finally(() => {
                console.log("done")
            })

            return user
        }
}
});
