
import { defineComponent, ref } from 'vue';
import { useApiStore } from '@/store/store-index';
import { useNavStore } from '@/store/store-index';
export default defineComponent({
  setup() {
    const store = useNavStore();
    const apiStore = useApiStore();
    const selectedItem = ref('');

    return {
      selectedItem,
      store,
      apiStore
    };
  },

  created () {    
    this.apiStore.init();
    this.store.selectedNavItem = 'profile'
    this.$router.push('/profile');
  }
});
