import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ProfileView from '../views/profile/profileHome.vue';
import LoginView from '../views/login/loginIndex.vue';
import PointsDeVente from '../views/pos/pdvHome.vue';
import NotificationVue from '../views/notification/notificationHome.vue';
import InvoicesVue from '../views/invoices/invoicesHome.vue';
import AssociesVue from '../views/associes/associesHome.vue';
import ModifDetailsUser from '../views/password/passwordHome.vue';
import SettingsVue from '../views/settings/settingsHome.vue';
import LoadingView from '../views/loading/loadingIndex.vue';


// import requestManager from '@/helpers/RequestManager';

import {  useApiStore, useAuthStore } from '@/store/store-index';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'start',
    component: ProfileView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/pos',
    name: 'pos',
    component: PointsDeVente
  },
  {
    path: '/notification',
    name: 'notification',
    component: NotificationVue
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: InvoicesVue
  },
  {
    path: '/associes',
    name: 'associes',
    component: AssociesVue
  },
  {
    path: '/password',
    name: 'password',
    component: ModifDetailsUser
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsVue
  },
  {
    path: '/loading',
    name: 'loading',
    component: LoadingView
  },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
  if(to.name === 'start') {
    return next({name: 'share-token'});
  }
  if(to.name === 'login') {
    const authStore = useAuthStore();
    const apiStore = useApiStore();
    authStore.isLogged = false
    apiStore.$reset();
  }
  next();
});


export default router;
