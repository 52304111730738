
  import { defineComponent } from 'vue';
  import { useApiStore } from '@/store/store-index';
  import "@vuepic/vue-datepicker/dist/main.css";

  export default defineComponent({
    setup() {
      const apiStore = useApiStore();
      return {
        apiStore,
      };
    },
    data() {
      return {
        selectedPos: [],
        alias: {},
        password: null,
        pdfsrc: '',
      };
    },
    computed: {},
    methods: {
    },
  });
  