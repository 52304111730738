
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: '',
        },
        modelValue: {
            required: true,
            type: String,
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            isPassword: true,
            value: '',
        };
    },
    computed: {
        type () :string {
            return this.isPassword ? 'password' : 'text';
        },
        text () :string {
            let text = this.isPassword ? 'display' : 'hide';
            return this.$t(text);
        }
    },
    methods: {
        renderValue (val :string) {
            this.$emit('update:modelValue', val);
            return this.value;
        }
    }
});
