const refreshToken = function (token: string) {
    localStorage.setItem('accessToken', token);    
}

import { useApiStore } from "@/store/store-index";

export default function init (): void {
    window.addEventListener('message', (e: any) => {
        if(e.origin !== process.env.VUE_APP_PARENT) return;
    }, false)
}