import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-password-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_form_input, {
      id: _ctx.id,
      formatter: _ctx.renderValue,
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      required: _ctx.required
    }, null, 8, ["id", "formatter", "type", "placeholder", "required"]),
    _createElementVNode("a", {
      "href.prevent": "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPassword = !_ctx.isPassword))
    }, _toDisplayString(_ctx.text), 1)
  ]))
}