// stores/nav.js

import { defineStore } from 'pinia';

export const useNavStore = defineStore({
  id: 'nav',
  state: () => ({
    selectedNavItem: '',
  }),
  actions: {
    selectNavItem(item: any) {
      this.selectedNavItem = item;
    },
  },
});

