
import { defineComponent } from 'vue';
import { useApiStore } from './store/api';
import NavbarWithComponent from '@/components/NavbarWithComponent.vue';

export default defineComponent({
    components:  {   NavbarWithComponent },
    setup() {
        const apiStore = useApiStore();
        return {
            apiStore,
            NavbarWithComponent
        };
    },
    created() {
        this.apiStore.init();
    },
});
