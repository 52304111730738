
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        show: {
            required: true,
            type: Boolean,
        },
        variant: {
            required: true,
            type: String,
        }
    },

    computed: {
        icon () {
            switch(this.variant) {
                case 'danger':
                    return 'fa-circle-exclamation';
                case 'success':
                    return 'fa-circle-check';
                default:
                    return '';
            }
        }
    }
});
