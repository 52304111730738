import iRules from '@/interfaces/formValidation/iRules';
import { checkElementValidity } from 'lib_common';

export default function (rules: any, data: any, i18n: any) {
    const validation: any = {};

    for (const ruleKey in rules) {
        const rule: iRules = rules[ruleKey];
        validation[ruleKey] = checkElementValidity(rule.rule, rule.message || '', data[ruleKey], i18n);
    }

    validation.allValid = () => {
        return Object.values(validation)
            .filter((val) => !(val instanceof Function))
            .every((val: any) => val.state === true);
    };

    return validation;
}
